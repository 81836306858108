<template>
  <!-- 权限管理-角色管理 -->
  <div class="container">
    <a-page-header title="角色权限管理">
      <template slot="extra">
        <a-input-search
          placeholder="输入角色名称"
          enter-button
          @search="onSearch"
        />
        <a-button type="primary" @click="handleModalOpen">
          新增角色
        </a-button>
      </template>
    </a-page-header>

    <div>
      <div class="main-content" v-for="(item, index) in lists" :key="item.id">
        <div class="main-content-header">
          <div class="item">
            <div class="title">
              <span class="title-1">{{ item.name }}</span>
              <span class="title-2">（{{ item.description }}）</span>
            </div>
          </div>
          <div class="item">
            <a-button
              type="primary"
              @click="handleDrawerOpen({ role_id: item.id, index: index })"
            >
              新增等级
            </a-button>
            <!-- <a-button
              @click="
                handleModalEdit({
                  name: item.name,
                  description: item.description
                })
              "
            >
              编辑
            </a-button> -->
          </div>
        </div>
        <div class="table-wrap">
          <a-table
            :columns="columns"
            :data-source="item.levelList"
            :pagination="false"
            :row-key="(record) => record.id"
            :loading="loading"
            :scroll="{ x: 1090 }"
          >
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].statu"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-button
                type="link"
                @click="
                  $jump({
                    path: '/roleBasedAccessControlSet',
                    query: {
                      role_id: item.id,
                      role_level_id: record.id,
                      name: record.anotherName
                    }
                  })
                "
              >
                权限设置
              </a-button>
              <a-button type="link">
                关联账号
              </a-button>
              <!-- <a-button
                type="link"
                @click="
                  handleDrawerEdit({
                    id: record.id,
                    parent_id: record.parent_id,
                    anotherName: record.anotherName,
                    remark: record.remark,
                    index: index
                  })
                "
              >
                编辑
              </a-button> -->
              <a-button type="link">
                删除
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <a-modal
      title="新建角色"
      :visible="modalVisible"
      :confirm-loading="confirmModalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-form-model ref="ruleModalForm" :model="modalForm" :rules="modalRules">
        <a-form-model-item ref="modalName" label="角色名称" prop="name">
          <a-input
            v-model="modalForm.name"
            placeholder="请输入角色名称"
            @blur="
              () => {
                $refs.modalName.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="modalDescription"
          label="角色描述"
          prop="description"
        >
          <a-input
            v-model="modalForm.description"
            placeholder="请输入角色描述"
            @blur="
              () => {
                $refs.modalDescription.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="modalStatus" label="是否启用" prop="status">
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model="modalForm.status"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <drawer title="新建等级" :visible="drawerVisible" :width="375">
      <a-form :form="drawerForm" layout="vertical" @submit="handleDrawerSubmit">
        <a-form-item label="角色名称">
          <a-input disabled v-model="roleName" placeholder="请输入角色名称" />
        </a-form-item>
        <a-form-item label="上级等级" v-if="isRoleLevel">
          <a-input disabled v-model="roleLevel" />
        </a-form-item>
        <a-form-item label="等级别名">
          <a-input
            v-decorator="[
              'anotherName',
              {
                rules: [{ required: true, message: '请输入角色别名' }]
              }
            ]"
            placeholder="请输入角色别名"
          />
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-decorator="['remark']" placeholder="请输入备注" />
        </a-form-item>
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose">
            取消
          </a-button>
          <a-button type="primary" html-type="submit">
            提交
          </a-button>
        </div>
      </a-form>
    </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '@/components/drawer/index'
let role_id = '' // 角色id
export default {
  data() {
    return {
      lists: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '等级别名',
          dataIndex: 'anotherName',
          width: 200
        },
        {
          title: '角色等级',
          dataIndex: 'name',
          width: 100
        },
        // {
        //   title: '关联账号数',
        //   dataIndex: 'name',
        //   width: 100
        // },
        {
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 330,
          scopedSlots: { customRender: 'action' }
        }
      ],
      statuText: [
        {
          text: '禁用',
          statu: 'default'
        },
        {
          text: '启用',
          statu: 'success'
        }
      ],
      loading: false,
      confirmModalLoading: false,
      modalVisible: false,
      modalForm: {
        name: '',
        description: '',
        status: true
      },
      modalRules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        description: [
          { required: true, message: '请输入角色描述', trigger: 'blur' }
        ]
      },
      drawerVisible: false,
      drawerForm: this.$form.createForm(this),
      roleName: '', // 角色名称（只做样式展示）
      roleLevelId: '',
      isRoleLevel: 1, // 是否显示上级等级（只做样式展示）
      roleLevel: '' // 上级等级（只做样式展示）
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    drawer
  },
  created() {
    this.getRoleBasedAccessControl()
  },
  methods: {
    onSearch() {},
    getRoleBasedAccessControl() {
      // 获取角色列表
      this.$axios.getRoleBasedAccessControl().then((res) => {
        this.lists = res.data.data
      })
    },
    handleModalOpen() {
      // 打开新建角色
      this.modalVisible = true
    },
    handleModalOk() {
      // 确定新建角色
      this.$refs.ruleModalForm.validate((valid) => {
        if (valid) {
          this.confirmModalLoading = true
          const data = {
            name: this.modalForm.name,
            description: this.modalForm.description,
            status: this.modalForm.status ? 1 : 0
          }

          this.$axios.addRole(data).then(() => {
            this.$message.success('操作成功')
            this.handleModalCancel()
            this.getRoleBasedAccessControl()
          })
        } else {
          return false
        }
      })
    },
    handleModalEdit(e) {
      // 编辑角色
      this.modalForm.name = e.name
      this.modalForm.description = e.description
      this.modalVisible = true
    },
    handleModalCancel() {
      // 取消新建角色
      this.$refs.ruleModalForm.resetFields()
      this.modalVisible = false
      this.confirmModalLoading = false
    },
    handleDrawerOpen(e) {
      // 打开新建角色等级
      role_id = e.role_id
      const role = this.lists[e.index]
      this.roleName = role.name
      const l = role.levelList.length
      if (l) {
        this.isRoleLevel = true
        this.roleLevel = role.levelList[l - 1].name
      } else {
        this.isRoleLevel = false
      }

      this.drawerVisible = true
    },
    handleDrawerEdit(e) {
      // 编辑角色等级
      this.roleLevelId = e.id
      const role = this.lists[e.index]
      this.roleName = role.name
      const l = role.levelList.length
      if (l && e.parent_id > 0) {
        this.isRoleLevel = true
        this.roleLevel = role.levelList[l - 1].name
      } else {
        this.isRoleLevel = false
      }
      this.drawerVisible = true

      setTimeout(() => {
        this.$nextTick(() => {
          this.drawerForm.setFieldsValue({
            anotherName: e.anotherName,
            remark: e.remark
          })
        })
      }, 100)
    },
    handleDrawerClose() {
      // 关闭角色等级
      this.drawerVisible = false
      this.isRoleLevel = true
      this.roleLevelId = ''
      this.roleLevel = ''
      this.drawerForm.resetFields()
    },
    handleDrawerSubmit(e) {
      // 提交新建角色等级
      e.preventDefault()
      this.drawerForm.validateFields((err, values) => {
        if (!err) {
          values.role_id = role_id
          this.$axios.addRoleLevel(values).then(() => {
            this.$message.success('操作成功')
            this.handleDrawerClose()
            this.getRoleBasedAccessControl()
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.container .main-content:not(:last-child) {
  padding: 24px 24px 0 24px;
}
</style>
