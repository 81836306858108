var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":"角色权限管理"}},[_c('template',{slot:"extra"},[_c('a-input-search',{attrs:{"placeholder":"输入角色名称","enter-button":""},on:{"search":_vm.onSearch}}),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleModalOpen}},[_vm._v(" 新增角色 ")])],1)],2),_c('div',_vm._l((_vm.lists),function(item,index){return _c('div',{key:item.id,staticClass:"main-content"},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"title-2"},[_vm._v("（"+_vm._s(item.description)+"）")])])]),_c('div',{staticClass:"item"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDrawerOpen({ role_id: item.id, index: index })}}},[_vm._v(" 新增等级 ")])],1)]),_c('div',{staticClass:"table-wrap"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":item.levelList,"pagination":false,"row-key":function (record) { return record.id; },"loading":_vm.loading,"scroll":{ x: 1090 }},scopedSlots:_vm._u([{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].statu,"text":_vm.statuText[record.status].text}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$jump({
                  path: '/roleBasedAccessControlSet',
                  query: {
                    role_id: item.id,
                    role_level_id: record.id,
                    name: record.anotherName
                  }
                })}}},[_vm._v(" 权限设置 ")]),_c('a-button',{attrs:{"type":"link"}},[_vm._v(" 关联账号 ")]),_c('a-button',{attrs:{"type":"link"}},[_vm._v(" 删除 ")])]}}],null,true)})],1)])}),0),_c('a-modal',{attrs:{"title":"新建角色","visible":_vm.modalVisible,"confirm-loading":_vm.confirmModalLoading},on:{"ok":_vm.handleModalOk,"cancel":_vm.handleModalCancel}},[_c('a-form-model',{ref:"ruleModalForm",attrs:{"model":_vm.modalForm,"rules":_vm.modalRules}},[_c('a-form-model-item',{ref:"modalName",attrs:{"label":"角色名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入角色名称"},on:{"blur":function () {
              _vm.$refs.modalName.onFieldBlur()
            }},model:{value:(_vm.modalForm.name),callback:function ($$v) {_vm.$set(_vm.modalForm, "name", $$v)},expression:"modalForm.name"}})],1),_c('a-form-model-item',{ref:"modalDescription",attrs:{"label":"角色描述","prop":"description"}},[_c('a-input',{attrs:{"placeholder":"请输入角色描述"},on:{"blur":function () {
              _vm.$refs.modalDescription.onFieldBlur()
            }},model:{value:(_vm.modalForm.description),callback:function ($$v) {_vm.$set(_vm.modalForm, "description", $$v)},expression:"modalForm.description"}})],1),_c('a-form-model-item',{ref:"modalStatus",attrs:{"label":"是否启用","prop":"status"}},[_c('a-switch',{attrs:{"checked-children":"开","un-checked-children":"关"},model:{value:(_vm.modalForm.status),callback:function ($$v) {_vm.$set(_vm.modalForm, "status", $$v)},expression:"modalForm.status"}})],1)],1)],1),_c('drawer',{attrs:{"title":"新建等级","visible":_vm.drawerVisible,"width":375}},[_c('a-form',{attrs:{"form":_vm.drawerForm,"layout":"vertical"},on:{"submit":_vm.handleDrawerSubmit}},[_c('a-form-item',{attrs:{"label":"角色名称"}},[_c('a-input',{attrs:{"disabled":"","placeholder":"请输入角色名称"},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=$$v},expression:"roleName"}})],1),(_vm.isRoleLevel)?_c('a-form-item',{attrs:{"label":"上级等级"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.roleLevel),callback:function ($$v) {_vm.roleLevel=$$v},expression:"roleLevel"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"等级别名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'anotherName',
            {
              rules: [{ required: true, message: '请输入角色别名' }]
            }
          ]),expression:"[\n            'anotherName',\n            {\n              rules: [{ required: true, message: '请输入角色别名' }]\n            }\n          ]"}],attrs:{"placeholder":"请输入角色别名"}})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"placeholder":"请输入备注"}})],1),_c('div',{staticClass:"drawer-btn-wrap"},[_c('a-button',{on:{"click":_vm.handleDrawerClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 提交 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }